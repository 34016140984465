@charset "UTF-8";
/* Use this file to write IE specific override styles.
 * Import this file using the following HTML or equivalent:
 * <!--[if IE]>
 *   <link href="/stylesheets/ie.css" media="screen, projection" rel="stylesheet" type="text/css" />
 * <![endif]--> */
/*
// Global CSS Variables
//
*/
/*
//---------------
//
//
*/
/*  Core CSS file - standardizes styles across browsers.  
	Based on Yahoo's Developer Library CSS (reset.css and base.css) files.
	GSSI - Version 1.9 */
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, textarea, p, blockquote {
  margin: 0;
  padding: 0; }

form {
  display: inline; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

fieldset, img {
  border: 0; }

address, caption, cite, code, dfn, th, var {
  font-style: normal;
  font-weight: normal; }

em {
  font-style: italic; }

h1, h2, h3, h4, h5, h6 {
  font-size: 100%; }

h1, h2, h3, h4, h5, h6, strong {
  font-weight: bold; }

caption, th {
  text-align: left; }

q:before, q:after {
  content: ''; }

abbr, acronym {
  border: 0; }

/* Some standard GSSI styling */
body {
  background-color: white; }

.c-w1 blockquote, .c-w1 ul, .c-w1 ol {
  margin: 0.25em 0 1em; }

.c-w1 blockquote {
  margin-left: 2em;
  margin-right: 2em; }

.c-w1 ol, .c-w1 ul {
  margin-left: 2em; }

.c-w1 ol > li {
  list-style: decimal outside; }

.c-w1 ul > li {
  list-style: disc outside; }

p, fieldset {
  margin: 0 0 1em; }

p, td {
  line-height: 1.5; }

/* Navigation lists <ul class="nav"> */
ul, li, ol, .nl, .nav, .nl li, .nav li, .nav ul {
  margin: 0;
  padding: 0;
  list-style: none; }

/* HTML5 Elements */
section, article, aside, footer, header, nav, hgroup {
  display: block; }

/*clearfix*/
.cf:after {
  content: "";
  display: table;
  clear: both; }

/* Accessability */
.element-invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px); }

/* Images
-------------------------------------------------------------- */
/*doc
---
title: Content Images
name: content_images
category: Content Classes
---

                                                        

<div class='entry-content'>
  <h3>Image class=alignleft</h3>
  <img src='../templates/images/sample.jpg' class='alignleft' />                
  <p>Mlkshk Williamsburg +1, swag flannel retro yr put a bird on it 
  disrupt street art keffiyeh selvage wolf trust fund farm-to-table. 
  DIY tousled pour-over tattooed, occupy plaid lomo McSweeney's Bushwick 90's. 
  Leggings Intelligentsia sartorial drinking vinegar, meh vegan umami migas Etsy bespoke.
   Occupy Banksy salvia ethical, Helvetica Blue Bottle McSweeney's DIY. 
   Try-hard plaid PBR Helvetica, Wes Anderson keytar drinking vinegar put a bird on it 
   hashtag 3 wolf moon meggings Vice Banksy viral. Keffiyeh post-ironic cornhole
    single-origin coffee readymade fanny pack. Organic listicle quinoa skateboard 
    squid cliche flannel dreamcatcher.</p>


  <h3>Image class=alignright</h3>
  <img src='../templates/images/sample.jpg' class='alignright' />
  <p>Mlkshk Williamsburg +1, swag flannel retro yr put a bird on it 
  disrupt street art keffiyeh selvage wolf trust fund farm-to-table. 
  DIY tousled pour-over tattooed, occupy plaid lomo McSweeney's Bushwick 90's. 
  Leggings Intelligentsia sartorial drinking vinegar, meh vegan umami migas Etsy bespoke.
   Occupy Banksy salvia ethical, Helvetica Blue Bottle McSweeney's DIY. 
   Try-hard plaid PBR Helvetica, Wes Anderson keytar drinking vinegar put a bird on it 
   hashtag 3 wolf moon meggings Vice Banksy viral. Keffiyeh post-ironic cornhole
    single-origin coffee readymade fanny pack. Organic listicle quinoa skateboard 
    squid cliche flannel dreamcatcher.</p>

  <h3>Image class=aligncenter</h3>
  <img src='../templates/images/sample.jpg' class='aligncenter' />
  <p>Mlkshk Williamsburg +1, swag flannel retro yr put a bird on it 
  disrupt street art keffiyeh selvage wolf trust fund farm-to-table. 
  DIY tousled pour-over tattooed, occupy plaid lomo McSweeney's Bushwick 90's. 
  Leggings Intelligentsia sartorial drinking vinegar, meh vegan umami migas Etsy bespoke.
   Occupy Banksy salvia ethical, Helvetica Blue Bottle McSweeney's DIY. 
   Try-hard plaid PBR Helvetica, Wes Anderson keytar drinking vinegar put a bird on it 
   hashtag 3 wolf moon meggings Vice Banksy viral. Keffiyeh post-ironic cornhole
    single-origin coffee readymade fanny pack. Organic listicle quinoa skateboard 
    squid cliche flannel dreamcatcher.</p>
</div>

*/
.entry-content img {
  margin: 0 0 1em 0; }

.alignleft,
img.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em; }

.alignright,
img.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em; }

.aligncenter,
img.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.wp-caption {
  text-align: center;
  margin-bottom: 1.5em; }

.wp-caption img {
  border: 0 none;
  margin: 0;
  padding: 0; }

.wp-caption p.wp-caption-text {
  margin: 0; }

.wp-smiley {
  max-height: 1em;
  margin: 0 !important; }

.gallery dl {
  margin: 0; }

.gallery-caption {
  margin: -1.5em 0 0 0; }

/* Pullquotes
-------------------------------------------------------------- */
blockquote.left {
  float: left;
  margin-left: 0;
  margin-right: 20px;
  text-align: right;
  width: 33%; }

blockquote.right {
  float: right;
  margin-left: 20px;
  margin-right: 0;
  text-align: left;
  width: 33%; }

/* -------------------------------------------------------------- 
   
   Basic WordPress Blog Styles   
     
-------------------------------------------------------------- */
/* Images
-------------------------------------------------------------- */
/*doc
---
title: Content Images
name: content_images
category: Content Classes
---

                                                        

<div class='entry-content'>
	<h3>Image class=alignleft</h3>
	<img src='./images/sample.jpg' class='alignleft' />                
	<p>Mlkshk Williamsburg +1, swag flannel retro yr put a bird on it 
	disrupt street art keffiyeh selvage wolf trust fund farm-to-table. 
	DIY tousled pour-over tattooed, occupy plaid lomo McSweeney's Bushwick 90's. 
	Leggings Intelligentsia sartorial drinking vinegar, meh vegan umami migas Etsy bespoke.
	 Occupy Banksy salvia ethical, Helvetica Blue Bottle McSweeney's DIY. 
	 Try-hard plaid PBR Helvetica, Wes Anderson keytar drinking vinegar put a bird on it 
	 hashtag 3 wolf moon meggings Vice Banksy viral. Keffiyeh post-ironic cornhole
	  single-origin coffee readymade fanny pack. Organic listicle quinoa skateboard 
	  squid cliche flannel dreamcatcher.</p>


	<h3>Image class=alignright</h3>
	<img src='./images/sample.jpg' class='alignright' />
	<p>Mlkshk Williamsburg +1, swag flannel retro yr put a bird on it 
	disrupt street art keffiyeh selvage wolf trust fund farm-to-table. 
	DIY tousled pour-over tattooed, occupy plaid lomo McSweeney's Bushwick 90's. 
	Leggings Intelligentsia sartorial drinking vinegar, meh vegan umami migas Etsy bespoke.
	 Occupy Banksy salvia ethical, Helvetica Blue Bottle McSweeney's DIY. 
	 Try-hard plaid PBR Helvetica, Wes Anderson keytar drinking vinegar put a bird on it 
	 hashtag 3 wolf moon meggings Vice Banksy viral. Keffiyeh post-ironic cornhole
	  single-origin coffee readymade fanny pack. Organic listicle quinoa skateboard 
	  squid cliche flannel dreamcatcher.</p>

	<h3>Image class=aligncenter</h3>
	<img src='./images/sample.jpg' class='aligncenter' />
	<p>Mlkshk Williamsburg +1, swag flannel retro yr put a bird on it 
	disrupt street art keffiyeh selvage wolf trust fund farm-to-table. 
	DIY tousled pour-over tattooed, occupy plaid lomo McSweeney's Bushwick 90's. 
	Leggings Intelligentsia sartorial drinking vinegar, meh vegan umami migas Etsy bespoke.
	 Occupy Banksy salvia ethical, Helvetica Blue Bottle McSweeney's DIY. 
	 Try-hard plaid PBR Helvetica, Wes Anderson keytar drinking vinegar put a bird on it 
	 hashtag 3 wolf moon meggings Vice Banksy viral. Keffiyeh post-ironic cornhole
	  single-origin coffee readymade fanny pack. Organic listicle quinoa skateboard 
	  squid cliche flannel dreamcatcher.</p>
</div>

*/
.entry-content img {
  margin: 0 0 1em 0; }

.alignleft,
img.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em; }

.alignright,
img.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em; }

.aligncenter,
img.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.wp-caption {
  text-align: center;
  margin-bottom: 1.5em; }

.wp-caption img {
  border: 0 none;
  margin: 0;
  padding: 0; }

.wp-caption p.wp-caption-text {
  margin: 0; }

.wp-smiley {
  max-height: 1em;
  margin: 0 !important; }

.gallery dl {
  margin: 0; }

.gallery-caption {
  margin: -1.5em 0 0 0; }

/* Pullquotes
-------------------------------------------------------------- */
blockquote.left {
  float: left;
  margin-left: 0;
  margin-right: 20px;
  text-align: right;
  width: 33%; }

blockquote.right {
  float: right;
  margin-left: 20px;
  margin-right: 0;
  text-align: left;
  width: 33%; }

html {
  box-sizing: border-box;
  font-size: 62.5%;
  /* Sets up the Base 10 stuff */
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.28em;
  font-family: 'Ubuntu', Arial, sans-serif;
  color: #666666; }

/*doc
---
title: Background Colors
name: background
category: Base CSS
---

We have a few background colors that can be used in various contexts.
These are not for use as the entire page background but instead for
specific components and modules on the page.

<div class="line txtC">
    <div class="col cols6 ">
      <div class="docSwatch primary-color"></div>
      <code>primary-color</code>
    </div>
    <div class="col cols6 ">
      <div class="docSwatch secondary-color"></div>
      <code>secondary-color</code>
    </div>
    <div class="col cols6 ">
      <div class="docSwatch accent-color"></div>
      <code>accent-color</code>
    </div>
    <div class="col cols6 lastCol">
      <div class="docSwatch  sec-accent-color"></div>
      <code>sec-accent-color</code>
    </div>
</div>
*/
.primary-color {
  background: #f84b01; }

.secondary-color {
  background: #2d2d2d; }

.accent-color {
  background: limegreen; }

.sec-accent-color {
  background: crimson; }

/*doc
---
title: Typography
name: typography
category: Base CSS
---

These are the heading sizes that you can use site wide.

<h1>This is a h1 tag 36px Lorem Ipsum</h1>

<h2>This is a h2 tag  25px Lorem Ipsum</h2>

<h3>This is a h3 tag  20px Lorem Ipsum</h3>

<h4>This is a h4 tag  18px Lorem Ipsum</h4>


<p>This is an example of our base font style 16px. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>


*/
h1, .sflistItemTitle {
  font-size: 60px;
  font-size: 6.0rem;
  color: #666666;
  line-height: 1.25em;
  margin-bottom: 0.7em;
  text-align: center;
  position: relative;
  padding-bottom: 20px;
  font-style: italic; }
  h1:after, .sflistItemTitle:after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 50%;
    margin-left: -50px;
    display: block;
    width: 100px;
    height: 5px;
    background: #f84b01; }

h2 {
  font-size: 30px;
  font-size: 3.0rem;
  color: #333333;
  font-weight: normal;
  line-height: 1.1em;
  margin-bottom: 0.67em; }

h3 {
  font-size: 21px;
  font-size: 2.1rem;
  color: #333333;
  font-weight: normal;
  line-height: 1.1em;
  margin-bottom: 0.67em; }

p {
  margin: 0 0 1.38em 0; }

/*doc
---
title: Lists
name: lists
category: Base CSS
---

These list styles are only applied in the content area. They are targeted
using the .c-w1 class.

<div class='c-w1'>
<ul>
  <li>List Item</li>
  <li>List Item</li>
  <li>List Item
      <ul>
        <li>List Item</li>
        <li>List Item</li>
        <li>List Item</li>
      </ul>
  </li>
  <li>List Item</li>
  <li>List Item</li>
</ul>

<ol>
  <li>List Item</li>
  <li>List Item</li>
  <li>List Item
      <ol>
        <li>List Item</li>
        <li>List Item</li>
        <li>List Item</li>
      </ol>
  </li>
  <li>List Item</li>
  <li>List Item</li>
</ol>
</div>

*/
.c-w1 ul, .c-w1 ol {
  margin: 1em 0 2em 2em; }

.c-w1 ul > li {
  margin-bottom: 10px;
  list-style: disc; }

.c-w1 ol > li {
  margin-bottom: 10px; }

/*doc
---
title: Buttons
name: buttons
category: Base CSS
---

```html_example_table                                                          

<button class="btn">Default</button>

<button class="btn btnSecondary">Secondary</button>

<button class="btn btnTertiary">Secondary</button>                      

```
*/
.btn {
  color: #fff;
  font-style: italic;
  font-size: 16px;
  font-size: 1.6rem;
  padding: 3px 15px;
  border-radius: 15px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  background: #f84b01;
  display: inline-block;
  margin-bottom: 10px;
  border: none; }
  .btn:hover {
    color: #fff;
    background: #000; }

.btnSecondary {
  background: green;
  color: #fff; }

.btnTertiary {
  background: FireBrick;
  color: #fff; }

img {
  max-width: 100%; }

/*doc
---
title: Forms
name: forms
category: Forms
---

Basic Form Styles. If in Wordpress, use to override Gravity Forms default styling.

<form>
          <div class='table-row'>
            <label class='center' for='field1'>Full Name</label>
            <div class='input-holder'>
              <input type='text' id='field1' name='field1' />
            </div>
          </div>
          <div class='table-row'>
            <label class='center' for='field2'>Email</label>
            <div class='input-holder'>
              <input type='text' id='field2' name='field2' />
            </div>
          </div>
          <div class='table-row'>
            <label class='center' for='field3'>Phone Number</label>
            <div class='input-holder'>
              <input type='text' id='field3' name='field3' />
            </div>
          </div>

          <div class='table-row'>
            <legend>Select a Choice</legend>
            
              <div class='input-holder'>
                <input type="radio" value='First Choice' id='Field5_0' name='Field5' />
                <label for="Field5_0">First Choice</label>
              </div>
              <div class='input-holder'>
                <input type="radio" value='First Choice' id='Field5_1' name='Field5' />
                <label for="Field5_1">Second Choice</label>
              </div>
              <div class='input-holder'>
                <input type="radio" value='First Choice' id='Field5_2' name='Field5' />
                <label for="Field5_2">Third Choice</label>
              </div>
            
          </div>

          <div class='table-row'>
            <legend>Check all that apply</legend>
            <div class='input-holder'>
              <input type='checkbox' value='First Choice' id='Field6' name='Field6' />
              <label for="Field6">First Choice</label>
            </div>
            <div class='input-holder'>
              <input type='checkbox' value='Second Choice' id='Field7' name='Field7' />
              <label for="Field7">Second Choice</label>
            </div>
            <div class='input-holder'>
              <input type='checkbox' value='Third Choice' id='Field8' name='Field8' />
              <label for="Field8">Third Choice</label>
            </div>
          </div>
          <div class=='table-row'>
            <label for='Field9' class='center'>Select a Choice</label>
            <div class='input-holder'>
              <select id='Field9' name='Field9'>
                <option value='First Choice'>Option 1</option>
                <option value='Second Choice'>Option 2</option>
                <option value='Third Choice'>Option 3</option>
                <option value='Fourth Choice'>Option 4</option>
                <option value='Fifth Choice'>Option 5</option>
              </select>
            </div>
        </form>

*/
input[type='text'] {
  width: 100%;
  height: 40px;
  font-size: 20px;
  padding: 10px 15px;
  line-height: 20px; }

select {
  width: 100%;
  height: 50px;
  font-size: 20px;
  padding: 10px 15px;
  line-height: 20px; }

input[type='submit'] {
  color: #fff;
  font-style: italic;
  font-size: 16px;
  font-size: 1.6rem;
  padding: 3px 15px;
  border-radius: 15px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  background: #f84b01;
  display: inline-block;
  margin-bottom: 10px;
  border: none;
  margin: 10px 0; }
  input[type='submit']:hover {
    color: #fff;
    background: #000; }

.table-row {
  margin-bottom: 20px;
  clear: both;
  margin: 0 0 10px;
  overflow: hidden;
  padding: 1px; }

.table-row > label, legend {
  float: left;
  padding-right: 25px;
  width: 25%;
  text-align: left; }

label.center {
  line-height: 40px; }

/***** Tables***/
.c-w1 table {
  margin: 30px 0;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.73em;
  border-top: 5px solid #f84b01; }
  .c-w1 table tr {
    border-bottom: 1px solid #f84b01; }
  .c-w1 table th {
    padding: 10px;
    text-transform: uppercase;
    font-weight: 700; }
  .c-w1 table td {
    padding: 10px; }
  .c-w1 table caption {
    text-transform: uppercase;
    color: #f84b01;
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: 700;
    padding-bottom: 10px; }

/*doc
---
title: Text Links
name: text_links
category: Base CSS
---

Basic link styles.

<p><a href='thelink'>This is a link</a></p>
<p><a href='#'>This is a visited Link</a></p>



*/
/* Remember: LoVe For Hate */
a {
  transition: all .5s;
  color: #f84b01;
  text-decoration: underline; }

a:hover, a:focus {
  text-decoration: none; }

/**********************************
Helpers
**********************************/
.mobile {
  display: none; }

/**********************************
Carousels (responsive slides)
**********************************/
.rslides {
  position: relative;
  list-style: none;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0; }

.rslides li {
  -webkit-backface-visibility: hidden;
  position: absolute;
  display: none;
  width: 100%;
  left: 0;
  top: 0; }

.rslides img {
  display: block;
  height: auto;
  float: left;
  width: 100%;
  border: 0; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-loading .slick-list {
    background: #fff url("./ajax-loader.gif") center center no-repeat; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px;
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }

.slick-prev:before, .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/*--------------------------------------------------------------
# Tabs
--------------------------------------------------------------*/
.tabs-accordion {
  margin: 20px 0; }

.tabs-accordion ul.tab-header {
  margin: 0px;
  background: url(/img/bg/grid-pattern-fine.png);
  width: 100%;
  display: block;
  border-top: 5px solid #f84b01; }
  .tabs-accordion ul.tab-header:after {
    content: "";
    display: table;
    clear: both; }

.tabs-accordion ul.tab-header li {
  list-style: none;
  display: inline-block;
  height: 70px;
  padding: 20px 25px;
  margin: 0px;
  line-height: 30px;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  transition: all .25s; }

.tabs-accordion .tab-header li[aria-selected='true'] {
  background: #f84b01;
  color: #fff;
  outline: none; }

.tabs-accordion .tab-content {
  background: #f5f6f6; }

.tabs-accordion .tab-title {
  display: none; }

.tabs-accordion .tab-box {
  display: block;
  width: 100%;
  clear: both;
  padding: 20px; }

.tabs-accordion .tab-box[aria-hidden='true'] {
  display: none; }

/***************************
Accordions
***************************/
.sflistExpandAllLnkWrp:after {
  content: "";
  display: table;
  clear: both; }

.sflistExpandAllLnkWrp a {
  float: right;
  display: block;
  text-align: left;
  text-decoration: none;
  color: #fff;
  background: url(/img/bg/grid-pattern-fine.png);
  background-size: 225px auto;
  line-height: 25px;
  padding: 10px 55px 10px 15px;
  position: relative;
  text-transform: uppercase;
  font-size: 15px;
  margin: 0 0 15px 0; }
  .sflistExpandAllLnkWrp a:after {
    content: '';
    display: block;
    position: absolute;
    right: 20px;
    top: 14px;
    width: 20px;
    height: 18px;
    background: url(../img/mobile/toggle-accordion.png) 0 0 no-repeat;
    background-size: 19px 97px;
    -webkit-border-radius: 50%;
    border-radius: 50%; }
  .sflistExpandAllLnkWrp a.sflistCollapseAllLnk:after {
    background-position: 0 bottom; }

.sfexpandableListWrp {
  margin: 20px 0; }
  .sfexpandableListWrp > .sflistList {
    margin: 0px; }
    .sfexpandableListWrp > .sflistList > .sflistitem {
      list-style: none; }
      .sfexpandableListWrp > .sflistList > .sflistitem .sflistItemTitle {
        margin-bottom: 0px; }
        .sfexpandableListWrp > .sflistList > .sflistitem .sflistItemTitle:after {
          display: none; }
      .sfexpandableListWrp > .sflistList > .sflistitem h3 a {
        display: block;
        text-align: left;
        text-decoration: none;
        color: #fff;
        border-top: 5px solid #f84b01;
        background: url(/img/bg/grid-pattern-fine.png);
        background-size: 225px auto;
        line-height: 25px;
        padding: 10px 48px 10px 15px;
        position: relative;
        text-transform: uppercase;
        font-size: 15px; }
        .sfexpandableListWrp > .sflistList > .sflistitem h3 a:after {
          content: '';
          display: block;
          position: absolute;
          right: 20px;
          top: 14px;
          width: 20px;
          height: 18px;
          background: url(../img/mobile/toggle-accordion.png) 0 0 no-repeat;
          background-size: 19px 97px;
          -webkit-border-radius: 50%;
          border-radius: 50%; }
      .sfexpandableListWrp > .sflistList > .sflistitem .sflistListItemExpanded:after {
        background-position: 0 bottom; }
      .sfexpandableListWrp > .sflistList > .sflistitem .sflistItemContent {
        padding-bottom: 20px;
        transition: all .75s; }

.accordion .tab-box .tab-title {
  border-top: 5px solid #f84b01;
  background: url(/img/bg/grid-pattern-fine.png);
  background-size: 225px auto;
  color: #fff;
  display: block;
  height: 50px;
  line-height: 25px;
  padding: 10px 15px;
  cursor: pointer;
  position: relative;
  text-transform: uppercase;
  font-size: 15px; }

.accordion .tab-box .tab-title:after {
  content: '';
  display: block;
  position: absolute;
  right: 20px;
  top: 14px;
  width: 20px;
  height: 18px;
  background: url(../img/mobile/toggle-accordion.png) 0 0 no-repeat;
  background-size: 19px 97px;
  -webkit-border-radius: 50%;
  border-radius: 50%; }

.accordion .tab-box .tab-title.open:after {
  background-position: 0 bottom; }

.accordion .tab-box .inner {
  max-height: 0px;
  overflow: hidden;
  transition: all .5s; }

.accordion .tab-box .inner[aria-hidden='false'] {
  max-height: 2000px;
  padding: 0 0 20px 0; }

/*****************************
Theme Basics Settings
******************************/
html {
  margin-top: 0 !important; }

.section-inner, .overview-logos {
  max-width: 1024px;
  margin: 0 auto;
  position: relative; }

.w-w2 {
  min-width: 970px; }

/*******************************************
Header
********************************************/
/*flyout styles*/
#wrapper {
  overflow: hidden;
  position: relative; }

#header, .h-w1, .h-w2 {
  height: 90px; }

/* Change to correct height */
#header {
  background: url(../img/bg/bg-header.png) top center repeat-x;
  z-index: 100; }

.h-w2 {
  z-index: 100; }

#mainnav, #topUtility, #logo {
  position: absolute; }

#menu-toggle {
  display: none;
  position: absolute;
  right: 20px;
  height: 30px;
  line-height: 30px;
  padding-left: 40px;
  width: 1px;
  /*set to 1 to hide text*/
  overflow: hidden;
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none; }
  #menu-toggle.open {
    width: 1px;
    font-size: 0px; }
  #menu-toggle:after, #menu-toggle span:before, #menu-toggle span:after {
    border-radius: 1px;
    height: 3px;
    width: 25px;
    background: #fff;
    display: block;
    content: '';
    position: absolute;
    right: 7px;
    cursor: pointer; }
  #menu-toggle span:before {
    top: 5px; }
  #menu-toggle span:after {
    top: 12px; }
  #menu-toggle:after {
    top: 19px; }
  #menu-toggle span:before, #menu-toggle span:after {
    transition: all 500ms ease-in-out; }
  #menu-toggle.open:after {
    transform: translate(-999px, 0); }
  #menu-toggle.open span:before {
    transform: translate(0, 7px) rotate(45deg); }
  #menu-toggle.open span:after {
    transform: translate(0, 0px) rotate(-45deg); }

#mainnav {
  z-index: 20;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding-left: 220px; }
  #mainnav li {
    float: left;
    margin-left: 20px;
    /*mobile show if selected */ }
    #mainnav li a {
      color: #fff;
      font-size: 15px;
      font-style: italic;
      text-decoration: none;
      font-weight: normal;
      display: block;
      height: 46px; }
      #mainnav li a:hover, #mainnav li a.sfSel {
        color: #f84b01; }
    #mainnav li.hasChildren > a:after {
      display: none; }
    #mainnav li.hasChildren.open > a:after,
    #mainnav li.hasChildren a.sfSel:after {
      background-position: 0 -40px; }
    #mainnav li ul {
      display: none;
      position: absolute;
      left: 0px;
      top: 46px;
      width: 100%;
      text-align: center;
      background: #414242;
      background: rgba(65, 66, 66, 0.6); }
      #mainnav li ul:before {
        position: absolute;
        left: -100%;
        top: 0px;
        content: "";
        width: 100%;
        height: 100%;
        background: #414242;
        background: rgba(65, 66, 66, 0.6); }
      #mainnav li ul:after {
        position: absolute;
        right: -100%;
        top: 0px;
        content: "";
        width: 100%;
        height: 100%;
        background: #414242;
        background: rgba(65, 66, 66, 0.6); }
      #mainnav li ul li {
        float: none;
        display: inline-block;
        margin: 0 8px; }
        #mainnav li ul li a {
          padding: 18px 0;
          height: auto;
          position: relative; }
    #mainnav li:hover ul {
      display: block; }

#logo {
  top: 5px;
  left: 0px;
  z-index: 100; }

#topUtility {
  bottom: 23px;
  right: 30px;
  z-index: 200; }
  #topUtility li {
    display: inline-block;
    vertical-align: top;
    margin-left: 5px; }
    #topUtility li a {
      display: block;
      width: 25px;
      padding-top: 25px;
      height: 1px;
      overflow: hidden;
      transition: none; }
      #topUtility li a:hover {
        background-position: 0px -42px; }
    #topUtility li.fb a {
      background-image: url(/build/img/bg/icon-facebook.png); }
    #topUtility li.tw a {
      background-image: url(/build/img/bg/icon-twitter.png); }
    #topUtility li.ig a {
      background-image: url(/build/img/bg/icon-instagram.png); }
    #topUtility li.lnkin a {
      background-image: url(/build/img/bg/icon-linkedin.png); }
      #topUtility li.lnkin a:hover {
        background-position: 0px -44px; }

#search-toggle {
  width: 25px;
  height: 26px;
  border: none;
  font-size: 0px;
  cursor: pointer;
  background: url(/build/img/bg/icon-search.png) 0 2px;
  position: absolute;
  bottom: 25px;
  right: 0px;
  z-index: 200;
  transition: none; }
  #search-toggle.active {
    background: url(/build/img/bg/icon-close-search.png) 0 2px;
    z-index: 600; }

#searchArea {
  position: absolute;
  right: 0;
  top: 0px;
  width: 23px;
  z-index: 200;
  text-align: right;
  transition: all .5s;
  width: 100%;
  padding: 102px 20px 20px 20px;
  background: #ccc;
  background: rgba(50, 50, 50, 0.8);
  display: none; }
  #searchArea.active {
    display: block; }
  #searchArea:before, #searchArea:after {
    position: absolute;
    left: -100%;
    top: 0;
    content: '';
    width: 100%;
    height: 100%;
    background: #252525;
    background: rgba(50, 50, 50, 0.9); }
  #searchArea:after {
    left: auto;
    right: -100%; }
  #searchArea .sfsearchBox {
    position: relative;
    margin: 0px; }
  #searchArea input[type='text'] {
    background: #fff;
    border: none;
    box-shadow: inset 1px 1px 7px 0px rgba(0, 0, 0, 0.4);
    height: 60px;
    line-height: 60px;
    color: #fff;
    font-style: italic;
    font-size: 24px;
    color: #666666; }
    #searchArea input[type='text']:focus {
      border-color: #66afe9;
      outline: 0;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  #searchArea input[type='submit'] {
    width: 65px;
    height: 60px;
    border: none;
    font-size: 0px;
    cursor: pointer;
    background: url(/build/img/bg/icon-search-large.png);
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 0; }

#leftCTA {
  position: fixed;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 126px;
  z-index: 200; }
  #leftCTA ul {
    float: right; }
  #leftCTA li {
    margin-bottom: 2px; }
  #leftCTA a {
    display: block;
    width: 90px;
    float: right;
    padding-top: 119px;
    height: 1px;
    overflow: hidden;
    background-color: #ef460f;
    background-color: rgba(239, 70, 15, 0.85);
    background-repeat: no-repeat;
    transition: all .5s;
    background-position: left 0; }
    #leftCTA a:hover {
      width: 126px;
      background-color: #ef460f; }
  #leftCTA .find-lot a {
    background-image: url(../img/bg/cta-findalot.png); }
  #leftCTA .pay-fine a {
    background-image: url(../img/bg/cta-payafine.png); }
  #leftCTA .appeal-fine a {
    background-image: url(../img/bg/cta-appealafine.png); }
  #leftCTA .live-chat-cta {
    position: relative;
    clear: both; }
    #leftCTA .live-chat-cta a {
      background-image: url(../img/bg/cta-livehelp.png); }
  #leftCTA #LP_DIV_1354819247501,
  #leftCTA #LP_DIV_1399589564950,
  #leftCTA #LP_DIV_1464253966112,
  #leftCTA #LP_DIV_1464166936103,
  #leftCTA #LPMcontainer-1471647853946-0 {
    display: none !important; }
  #leftCTA .live-chat-cta > div:nth-child(2) {
    display: inline-block !important;
    position: absolute !important;
    margin: 0 !important;
    padding: 0 !important;
    top: 0;
    left: 0;
    max-width: 100%;
    opacity: 0; }
  #leftCTA .live-chat-cta .LPMcontainer {
    height: 125px !important; }

body > .LPMoverlay {
  display: none !important; }
  body > .LPMoverlay img {
    display: none; }

/*******************************************
Content
********************************************/
.m-w1:after {
  content: "";
  display: table;
  clear: both; }

#banner {
  height: 430px;
  margin-top: -116px;
  position: relative;
  z-index: -1; }
  #banner img {
    position: absolute;
    left: 50%;
    margin-left: -960px;
    top: 0px;
    max-width: 1920px; }
  #banner .mobile img {
    display: none; }
  #banner .desktop img {
    display: block; }

#subnav {
  position: absolute;
  top: 398px;
  left: 0px;
  width: 100%;
  border-bottom: 1px solid #c4c6ba;
  text-align: center; }
  #subnav .m-subnav-title {
    display: none; }
  #subnav li {
    display: inline-block;
    vertical-align: top;
    float: none;
    margin: 0 15px; }
    #subnav li a {
      font-style: italic;
      color: #333;
      padding: 20px 0;
      display: block;
      text-decoration: none;
      font-size: 16px;
      font-size: 1.6rem; }
      #subnav li a.sfSel, #subnav li a:hover {
        color: #f84b01;
        font-weight: normal; }

#content {
  margin-top: 58px;
  padding-top: 45px;
  padding-bottom: 80px;
  padding-left: 80px;
  max-width: 850px;
  overflow: auto;
  /*for iframes */ }

/* Subnav appears after content-body in markup, 
	but often is shifted to the left */
/*******************************************
CTAs
********************************************/
#ctas {
  width: 100%;
  position: relative;
  z-index: 10;
  box-shadow: 0px 11px 13px 0px rgba(0, 0, 0, 0.16); }
  #ctas .sf_colsIn {
    margin: 0px; }
  #ctas img {
    display: block; }

.bottom-ctas {
  display: none;
  padding: 54px 0 160px 0;
  background: #ecedee; }
  .bottom-ctas .sf_3cols_2_34 {
    width: 33%; }
  .bottom-ctas .sf_colsOut .sf_colsIn {
    margin: 0 5px; }
  .bottom-ctas a {
    display: block;
    height: 368px;
    overflow: hidden;
    box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.5);
    position: relative;
    text-align: center;
    font-size: 22px;
    font-style: italic;
    font-weight: 500;
    text-decoration: none;
    color: #666666;
    padding: 66px 22% 0 22%;
    background-position: center top; }
    .bottom-ctas a:hover:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
      background: url(../img/bg/cta_hover.png);
      background-size: 100% 100%; }
    .bottom-ctas a span {
      display: block;
      margin: 22px auto 0 auto;
      color: #fff;
      background-color: #f84b01;
      border-radius: 10px;
      font-size: 14px;
      width: 100px;
      line-height: 27px; }
  .bottom-ctas .cta-electric {
    background-image: url(../img/cta/cta-electric.jpg); }
  .bottom-ctas .cta-film {
    background-image: url(../img/cta/cta-film.jpg); }
  .bottom-ctas .cta-reserve {
    background-image: url(../img/cta/cta-reserve.jpg); }

/*******************************************
Footer
********************************************/
.top-nav-btn {
  position: absolute;
  top: -133px;
  left: 50%;
  margin-left: -19px;
  display: block;
  height: 1px;
  overflow: hidden;
  width: 40px;
  padding-top: 45px;
  background: url(../img/bg/btn-top.png); }

#footer {
  clear: both;
  position: relative;
  height: 130px;
  padding: 50px 0 110px 0;
  font-size: 14px;
  background: url(../img/bg/bg-footer.png) center top;
  text-align: center;
  font-style: italic; }
  #footer:before {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    margin-left: -20px;
    display: block;
    height: 1px;
    overflow: hidden;
    width: 40px;
    padding-top: 40px;
    background: url(../img/bg/icon-park.png); }
  #footer a {
    color: #fff;
    text-decoration: none; }
    #footer a:hover {
      text-decoration: underline; }

#foot-sitemap li {
  display: inline-block;
  margin: 0 5px 10px 5px; }

#foot-copy {
  margin-right: 15px;
  margin-bottom: 15px; }

#foot-copy, #foot-credit {
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
  color: #999999; }

#liveChat {
  position: fixed;
  left: 38px;
  bottom: 0px;
  background: #fff url(../img/bg/icon-chat.png) right center no-repeat;
  border-radius: 10px 10px 0 0;
  width: 322px;
  padding-left: 15px;
  height: 42px;
  line-height: 42px;
  font-size: 15px;
  font-style: italic;
  color: #333333;
  font-weight: 500; }

/***********************************
Brand Partners
**********************************/
.flex-ctas {
  display: none; }

.overview-logos {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
  font-style: italic;
  color: #333333;
  font-weight: 500; }
  .overview-logos .sf_colsOut {
    max-width: 1022px;
    margin: 0 auto;
    position: relative;
    padding-left: 80px;
    height: auto; }
  .overview-logos li {
    display: inline-block;
    vertical-align: top;
    width: 24%;
    height: 75px;
    padding: 0 2%;
    overflow: hidden;
    margin-top: 35px; }
    .overview-logos li a {
      display: block;
      height: 100%;
      margin-top: 0px; }
      .overview-logos li a:after {
        display: none; }
    .overview-logos li img {
      height: 200%; }
    .overview-logos li:hover img {
      position: relative;
      top: -100%; }

/*********************************************
News
*********************************************/
.c-w1 .sfnewsList {
  margin: 0px; }
  .c-w1 .sfnewsList li {
    list-style: none;
    margin-bottom: 0px;
    padding: 20px 0;
    border-bottom: 1px solid #f84b01; }
    .c-w1 .sfnewsList li .sfnewsTitle a {
      text-decoration: none; }

.sfnewsAuthorAndDate {
  color: #f84b01;
  font-weight: 600;
  margin-bottom: 10px; }

/*********************************************
Custom Forms
*********************************************/
.orangetext {
  color: #F57F29; }

.validation-summary {
  color: red; }

.error ul {
  margin-top: 0;
  margin-bottom: 0; }

.apply-online-form ul.form-steps {
  margin: 0px; }
  .apply-online-form ul.form-steps > li {
    list-style: none;
    float: left;
    width: 33%;
    margin: 0px;
    padding: 25px 0;
    text-align: center; }
    .apply-online-form ul.form-steps > li .selected {
      font-weight: bold; }

.apply-online-form table {
  border-top: none; }
  .apply-online-form table tr {
    border-color: #ccc; }
  .apply-online-form table .rcTable tr {
    border: none; }
  .apply-online-form table td {
    width: 50%;
    vertical-align: middle; }
  .apply-online-form table select, .apply-online-form table input[type='text'], .apply-online-form table .rcInputCell input.riTextBox {
    width: 90% !important;
    margin-right: 2%;
    height: 33px;
    font-size: 14px;
    padding: 4px 15px;
    line-height: 20px; }
  .apply-online-form table input[type='text'] {
    height: 50px;
    font-size: 20px;
    padding: 10px 15px;
    line-height: 20px; }
  .apply-online-form table .start-date > div, .apply-online-form table .start-date > div > table {
    width: 90% !important; }
  .apply-online-form table .emailhidden {
    margin: 20px 0; }
    .apply-online-form table .emailhidden .tooltipContent {
      line-height: 1.5em;
      padding: 0 10px; }

.apply-online-form input[type='submit'] {
  color: #fff;
  font-style: italic;
  font-size: 16px;
  font-size: 1.6rem;
  padding: 3px 15px;
  border-radius: 15px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  background: #f84b01;
  display: inline-block;
  margin-bottom: 10px;
  border: none;
  border: none; }
  .apply-online-form input[type='submit']:hover {
    color: #fff;
    background: #000; }

table.form_table, table.apply-table {
  border-top: none;
  width: 100%; }
  table.form_table tr, table.apply-table tr {
    border-color: #ccc;
    border: none; }
  table.form_table td, table.apply-table td {
    width: 50%;
    vertical-align: top; }
  table.form_table select, table.form_table input[type='text'], table.apply-table select, table.apply-table input[type='text'] {
    width: 90% !important;
    margin-right: 2%;
    margin-bottom: 0px;
    height: 33px;
    font-size: 14px;
    padding: 4px 15px;
    line-height: 20px; }
  table.form_table input[type='radio'], table.apply-table input[type='radio'] {
    margin-right: 10px; }
  table.form_table br, table.apply-table br {
    display: none; }
  table.form_table .reasoncancelling br, table.apply-table .reasoncancelling br {
    display: block;
    margin: 5px; }
  table.form_table input[type='submit'], table.apply-table input[type='submit'] {
    color: #fff;
    font-style: italic;
    font-size: 16px;
    font-size: 1.6rem;
    padding: 3px 15px;
    border-radius: 15px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    background: #f84b01;
    display: inline-block;
    margin-bottom: 10px;
    border: none;
    border: none; }
    table.form_table input[type='submit']:hover, table.apply-table input[type='submit']:hover {
      color: #fff;
      background: #000; }

.monthly-pay input.txtEmail {
  text-transform: lowercase; }

/******capthca ************/
table.form_table .RadCaptcha,
.apply-table .RadCaptcha {
  text-align: right;
  padding-right: 31px; }
  table.form_table .RadCaptcha.rcInvalid > span,
  .apply-table .RadCaptcha.rcInvalid > span {
    color: red;
    font-weight: normal;
    font-size: 16px;
    display: block;
    margin-bottom: 15px; }
  table.form_table .RadCaptcha img,
  .apply-table .RadCaptcha img {
    float: right;
    margin-bottom: 10px; }
  table.form_table .RadCaptcha p,
  .apply-table .RadCaptcha p {
    width: 366px;
    clear: both;
    display: block;
    float: right;
    margin-bottom: 10px; }
  table.form_table .RadCaptcha label,
  .apply-table .RadCaptcha label {
    display: block;
    margin-top: 10px; }

.reasoncancelling label {
  margin-bottom: 10px; }

/********* Pay Monthly Parking Tooltipp *************/
.showTip {
  position: relative; }
  .showTip:hover:after {
    content: 'CVV: Its a 3 or 4 digit number at the back of your credit card';
    display: block;
    width: 300px;
    height: auto;
    padding: 10px;
    background: #ccc;
    position: absolute;
    top: 20px;
    left: 0px;
    font-size: 13px;
    border: 1px solid #000; }

/*******************************
Events
*******************************/
.sfcalendarWrp table td {
  padding: 0; }

.sfcalendarWrp .rsContentScrollArea {
  height: auto !important;
  overflow: normal; }

.sfcalendarSidebar {
  display: none; }

/*********************************************
Editor fixes
*********************************************/
.sfPageEditor .mobile {
  display: block; }

.sfPageEditor #header {
  min-height: 500px; }

.sfPageEditor #banner {
  z-index: 0;
  height: auto; }
  .sfPageEditor #banner .image-wrapper {
    height: 500px; }

.sfPageEditor .side-accord {
  height: auto; }

.sfPageEditor #leftCTA {
  display: none; }

.sfPageEditor .banner-content-overlay {
  position: static !important; }

/*******************************************************
Search
********************************************************/
.sfsearchResultTitle {
  margin-top: 25px; }

/*******************************************************
Calendar
********************************************************/
.c-w1 .RadScheduler_Metro .rsHeader {
  background: #f84b01;
  border: none; }
  .c-w1 .RadScheduler_Metro .rsHeader h2 {
    color: #fff; }
  .c-w1 .RadScheduler_Metro .rsHeader ul {
    margin: 0px; }
    .c-w1 .RadScheduler_Metro .rsHeader ul a {
      border-color: #fff; }

/********************************************************
Grid
********************************************************/
/***************************************
Live Chat
****************************************/
#leftCTA .live-chat-cta {
  display: none; }
